import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { store } from 'react-notifications-component';
//import echo from './services/pusherService'; // Import Pusher service
import 'react-notifications-component/dist/theme.css';
import './index.css'; // Import your custom CSS file for styling

function App() {
  const [messages, setMessages] = useState([]);
  const [senderId, setSenderId] = useState('');
  const [userId, setUserId] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    if (userId && senderId) {
      fetchMessages();

//      const channel = echo.private(`chat.${userId}`);
      // channel.listen('MessageSent', (e) => {
      //   setMessages((prevMessages) => [...prevMessages, e.message]);
      //   store.addNotification({
      //     title: 'New Message!',
      //     message: e.message.chat_message,
      //     type: 'success',
      //     container: 'top-right',
      //     dismiss: {
      //       duration: 5000,
      //     },
      //   });
      // });

      // return () => {
      //   channel.stopListening('MessageSent');
      //   channel.unsubscribe();
      // };
    }
  }, [userId, senderId]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`https://newreact.gocarsbd.com/admin/public/api/get_data_click?user_id=${userId}&sender_id=${senderId}`);
      setMessages(response.data.message);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async () => {
    const formData = new FormData();
    formData.append('sender_id', senderId);
    formData.append('user_id', userId);
    formData.append('msg', messageInput);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      await axios.post('https://newreact.gocarsbd.com/admin/public/api/msginsert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessageInput('');
      setAttachment(null);
      fetchMessages();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h1>Chat App</h1>
      </div>
      <div className="message-container">
        {messages.map((message, index) => (
          <div key={index} className={message.from_user_id === senderId ? 'message sent' : 'message received'}>
            <p className="message-text float-end">{message.chat_message}</p>
            {message.attachment && (
              <a href={`https://newreact.gocarsbd.com/admin/public/attachments/${message.attachment}`} target="_blank" rel="noopener noreferrer">
                View Attachment
              </a>
            )}
            <span className="message-sender float-start">{message.from_user_name}</span>
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Type a message..."
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <input
          type="file"
          onChange={(e) => setAttachment(e.target.files[0])}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
      <div className="input-container-bottom">
        <input
          type="text"
          placeholder="Your User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Sender's ID"
          value={senderId}
          onChange={(e) => setSenderId(e.target.value)}
        />
      </div>
    </div>
  );
}

export default App;
